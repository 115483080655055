import React from 'react';
import { Box } from 'theme-ui';
import { ImageFullWidth } from '../../components';

const Gallery = (props) => {
  const { imagesList } = props.pageBody;
  return (
    <Box sx={galleryStyles.base}>
      {imagesList &&
        imagesList.map((image) => (
          <Box key={Math.random().toString(36).substring(7)} sx={galleryStyles.item}>
            <ImageFullWidth key={image._key} src={image?._rawAsset._ref} alt="alt" />
          </Box>
        ))}
    </Box>
  );
};

export default Gallery;

const galleryStyles = {
  item: {
    ':not(:last-child)': {
      mb: 2,
    },
  },
};
